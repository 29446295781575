import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import ProductOverview from './ProductOverview';

function Contact() {
  return (
      <div className="bg-gray-50 text-gray-800">
          <Navbar />
      {/* Hero */}
      <section className="py-16 bg-emerald-950 mb-20">
        <div className="container mx-auto px-4">
          <h1 className='text-white text-5xl font-semibold'>Contact Us</h1>
        </div>
      </section>
      
      {/* Contact */}
      <section className='px-16'>
        <div className='flex lg:flex-row flex-col justify-between items-center'>
          <div>
            <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-blue-950'>Get In Touch </span> <span className='text-emerald-950'>With Us</span></h1>
          </div>
          <div>
            <form action="" className="mt-4 px-0" id="form">
                    <div className='bg-red-500 text-center p-2 rounded font-medium hidden mb-10 text-white' id="error">
                      Oops, no such user!
                    </div>
                    <div>
                        <p className="text-blue-950 mb-3 font-bold">Name</p>
                        <input type="text" id="name" name="name" className="p-3 lg:w-96 w-80 bg-transparent border border-solid border-gray-500 rounded-md"/>
                    </div>
                    <div>
                        <p className="text-blue-950 my-4 font-bold">Email</p>
                        <input type="text" id="email" name="email" className="p-3 lg:w-96 w-80 bg-transparent border border-solid border-gray-500 rounded-md"/>
                    </div>
                    <div>
                        <p className="text-blue-950 my-4 font-bold">Message</p>
                        <textarea name="message" id="message" rows={5} className="p-3 lg:w-96 w-80 bg-transparent border border-solid border-gray-500 rounded-md"></textarea>
                    </div>
                    <button className="mt-10 p-3 rounded font-semibold hover:font-bold bg-green-600 text-white lg:w-full w-80">Contact Us Now</button>
                </form>
          </div>
        </div>
      </section>



      {/* Product Overview */}
      <ProductOverview />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Contact;
