import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import ProductOverview from './ProductOverview'
import protection from "../img/protection.jpg"
import privacy from "../img/privacy.jpg"
import fees from "../img/fees.png"
import reliability from "../img/reliability.png"
import assets from "../img/assets.png"
import tools from "../img/tools.png"

function Product() {
  return (
      <div className="bg-gray-50 text-gray-800">
          <Navbar />
      {/* Hero */}
      <section className="py-16 bg-emerald-950 mb-20">
        <div className="container mx-auto px-4">
          <h1 className='text-white text-5xl font-semibold'>Product</h1>
        </div>
          </section>



          {/* Bonds */}
          <section className='container mx-auto lg:px-0 px-5'>
              <h1 className='text-blue-950 font-bold lg:text-xl text-base'>What is Bond CFD Trading?</h1>
              <p className='font-medium mb-10'>A bond CFD is a form of financial derivative trading. When you trade a bond, you are taking a position on the price of the underlying instrument and not purchasing the instrument itself. This means that if you feel the bond you are trading will go up in value, you would take a long position and if you feel the value of the bond would fall, you would take a short position.</p>
              <h1 className='text-blue-950 font-bold lg:text-xl text-base'>(BCT) Bond CFDs are based off fixed income debt securities that pay investors a regular coupon in exchange for their investment. We offer the bonds products as a CFD with flexible lot sizing, so you can speculate on the price of the Bond by going long or short.</h1>
              <p className='font-medium mb-10'>There is no interest debited or credited on these Bonds CFDs, just like the underlying Futures markets that they’re based off. Again, this means you only have to worry about the price of the bond and whether you go long or short.
              Bond CFDs provided by (BCT) are based off highly rated government issued debt securities, including governments of the United States, Japan and Europe. Bonds offer traders the opportunity to speculate on interest rates and risk on/off sentiment, diversify a portfolio or reduce risk and build defensive positions during periods of economic weakness or uncertainty.</p>
          </section>
          <hr />
          {/* Bonds */}
          <section className='container mx-auto mt-10 lg:px-0 px-5'>
              <h1 className='text-blue-950 font-bold lg:text-xl text-base'>What are CFDs on Stocks?</h1>
              <p className='font-medium mb-10'>A stock CFD is the contract-for-difference of a specific stock that allows traders to speculate the direction of the stock price and open a position accordingly, long/buy position if they believe the price will rise, short/sell position if the speculation is that the price will fall.</p>
              <h1 className='text-blue-950 font-bold lg:text-xl text-base'>Trade Verse single stock CFD give traders the ability to trade the world’s most popular companies such as Apple, Facebook or BHP Billiton.</h1>
              <p className='font-medium mb-10'>We have listed the most popular stocks across the Australian and US markets to give you the best trading opportunities. Alternatively you can trade global macro themes with our special selection of US Exchange listed CFDs including a range of emerging markets Indices and popular such as the VanEck Vectors Gold Miners (GDX).Stocks are available exclusively on the Trade Verse MetaTrader 5 platform which offers advanced functionalities for both new and experienced traders who require world class execution and superior charting tools.</p>
          </section>
          <hr />
          {/* Bonds */}
          <section className='container mx-auto mt-10 lg:px-0 px-5'>
              <h1 className='text-blue-950 font-bold lg:text-xl text-base'>What is Indices CFD Trading?</h1>
              <p className='font-medium mb-10'>An index is a method to track the performance of a group of assets in a standardized way. Indexes typically measure the performance of a basket of securities intended to replicate a certain area of the market. These could be a broad-based index that captures the entire market, such as the S&P 500 Index or Dow Jones Industrial Average (DJIA), or more specialized such as indexes that track a particular industry or segment.</p>
              <h1 className='text-blue-950 font-bold lg:text-xl text-base'>Indices are the most popular form of CFDs. Trade Verse has a large range of Indices from around the world to choose from, including the Australian S&P 200 Index, UK FTSE 100 Index, US E-mini S&P 500 and US DJIA Index.</h1>
              <p className='font-medium mb-10'>A stock index is a good indicative measure of market performance. Indices such as the FTSE 100 and DJIA Index are baskets of blue chip stocks listed on the exchange and are generally a good measure of the current market sentiment. A change in the performance of any constituent stock in an index is reflected in a change in the overall value of that index. Indices have the advantage of allowing traders to take a wider view of a basket of stocks rather than taking a view on one individual stock alone. Online CFD and futures based indices are offered on both Trade Verse MetaTrader 4, 5, and BullCopytrade Platforms.</p>
          </section>

          {/* Why Choose Us? */}
      <section className="py-16 mt-10">
        <div className="container mx-auto px-4 flex flex-col items-center">
          <h1 className="lg:text-4xl text-xl font-bold mb-6 text-center text-blue-950">Stay with a Trusted Broker</h1>
          <p className="text-center mb-14">Discover why over 1 million of investors joined Trade Verse</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-10">
            <div
                className="bg-black lg:py-0 lg:px-0 px-10 py-20 flex flex-col items-center justify-center rounded-lg shadow-lg text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={protection} className='w-20' />
              <h3 className="text-xl font-semibold mb-2 text-white mt-10">Protection</h3>
                <p className="text-gray-300">Trade with confidence on a battle-tested platform with top-tier security.</p>
            </div>
            <div
                className="lg:py-0 lg:px-0 px-10 py-20 flex flex-col items-center justify-center rounded-lg shadow-lg text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
                style={{ background: "#3179ff" }}
              >
              <img src={privacy} className='w-20' />
              <h3 className="text-xl font-semibold mb-2 text-white mt-10">Privacy</h3>
                <p className="text-gray-100">Your data is yours, you decide what to share on your Trade Verse public profile.</p>
            </div>
            <div
                          className="flex flex-col items-center justify-center rounded-lg shadow-lg p-14 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
                          style={{ background: "#ffa500" }}
              >
              <img src={reliability} className='w-20' />
              <h3 className="text-xl font-semibold mb-2 text-white mt-10">Reliability</h3>
                <p className="text-gray-100">Find all the data, insights, and education you need for trading the markets.</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div
                className="bg-red-500 flex flex-col items-center justify-center rounded-lg shadow-lg p-14 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={assets} className='w-20' />
              <h3 className="text-xl font-semibold mb-2 text-white mt-10">1K+ Tradable assets</h3>
                <p className="text-gray-100">Choose from a variety of assets and build a widely diversified portfolio.</p>
            </div>
            <div
                className="bg-purple-500 flex flex-col items-center justify-center rounded-lg shadow-lg p-14 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={fees} className='w-20' />
              <h3 className="text-xl font-semibold mb-2 text-white mt-10">Low Fees</h3>
                <p className="text-gray-100">Pay less in fees and enjoy better returns in all your profitable trades.</p>
            </div>
            <div
                className="bg-green-500 flex flex-col items-center justify-center rounded-lg shadow-lg p-14 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={tools} className='w-20' />
              <h3 className="text-xl font-semibold mb-2 text-white mt-10">Professional Tools</h3>
                <p className="text-gray-100">Trade like a master with the help of professional charts and indicators</p>
            </div>
          </div>
        </div>
      </section>

      {/* Product Overview */}
      <ProductOverview />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Product;
