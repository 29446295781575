import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import banner from "../img/banner.png"
import ProductOverview from './ProductOverview'
import iq from "../img/iq.png"
import iq2 from "../img/iq2.png"
import a from "../img/a.png"
import b from "../img/b.png"
import c from "../img/c.png"
import d from "../img/d.png"
import e from "../img/e.png"
import f from "../img/f.png"
import start from "../vid/start.mp4"

function Company() {
  return (
      <div className="bg-gray-50 text-gray-800">
          <Navbar />
      {/* Hero */}
      <section className="py-16 bg-emerald-950 mb-20">
        <div className="container mx-auto px-4">
          <div className='flex lg:flex-row flex-col justify-between items-center lg:space-x-10 space-x-0 space-y-10 lg:space-y-0'>
                      <img src={iq} alt="" className='lg:w-auto w-60'/> 
                      <div className='text-center'>
                          <p className='text-white font-medium lg:text-6xl text-xl'>Invest, Trade, Copy</p>
                          <p className='text-white font-medium lg:text-6xl text-xl'>- All In One App</p>
                          <br />
                          <p className='text-gray-300 mb-10'>At Trade Verse, we believe that everyone should have easy, fast and free access to the global financial markets. That’s why we’ve built the most powerful app for finance.</p>
                          <Link to="/register" className='rounded-md bg-emerald-500 text-white py-3 px-10 hover:bg-emerald-600 hover:font-medium'>Start Trading</Link>
                      </div>       
          </div>
        </div>
      </section>

      {/* Invest */}
      <section>
        <div className="container flex lg:flex-row flex-col jusify-between items-center space-x-10 mx-auto px-4">
          <div className='lg:order-2 order'>
            <img src={banner} alt="" />
          </div>
          <div>
            <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-blue-950'>Invest in Tesla, EURGBP, Brent Oil and Apple Stock </span> <span className='text-green-950'>— All From A Single Account</span></h1>
            <p className='font-semibold text-blue-950 text-xl'>Invest</p>
            <p className="text-lg mb-6 text-gray-700">Trade the global markets (Real Stocks, CFDs on Commodities, Forex and CFDs on ETFs). Trade with real-time execution. Enjoy professional tools.</p>
          </div>
        </div>

      </section>

      {/* Our Community */}
      <section className='mt-28'>
        <div className="container flex lg:flex-row flex-col jusify-between items-center lg:space-x-10 space-x-0 space-y-10 lg:space-y-0 mx-auto px-4">
          <div>
            <video src={start} muted autoPlay loop></video>
          </div>
          <div>
            <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-blue-950'>Dive Into Stock Trading </span> <span className='text-green-950'>With Low Fees</span> <span className='text-blue-950'>And Completely Free Account</span></h1>
            <p className="text-lg mb-6 text-gray-700">At Trade Verse, we believe that everyone should have easy, fast and free access to the global financial markets.</p>
            <Link to="/register" className='bg-green-500 rounded-md text-white font-medium hover:bg-green-600 hover:font-semibold py-4 px-4'>
              Start Trading
            </Link>
          </div>
        </div>

      </section>

      {/* Why Choose Us? */}
      <section className="py-16 mt-10">
        <div className="container mx-auto px-4 flex flex-col items-center">
          <h1 className="lg:text-4xl text-xl font-bold mb-6 text-center"><span className='text-blue-950'>Why Do People Choose </span> <span className='text-green-950'>Trade Verse?</span></h1>
          <p className="text-center mb-14">We offer the best services around - from installations to repairs, maintenance, and more!</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-10">
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-14 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={a} className='' />
              <h3 className="text-xl font-medium mb-2 text-blue-800 mt-10">950+ Instruments And...</h3>
                <p className="text-gray-800">Trade CFDs, Forex, Stocks and/or choose any other market you prefer.</p>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-14 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={b} className='' />
              <h3 className="text-xl font-medium mb-2 text-blue-800 mt-10">Real-Time Order Execution</h3>
                <p className="text-gray-800">Stay on top of the market with our high-speed order processing.</p>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-14 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={c} className='' />
              <h3 className="text-xl font-medium mb-2 text-blue-800 mt-10">Mobile and Web Interface</h3>
                <p className="text-gray-800">Trade on the go with Trade Verse mobile apps for iOS and Android devices.</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-14 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={d} className='' />
              <h3 className="text-xl font-medium mb-2 text-blue-800 mt-10">Discover Copy-Trading</h3>
                <p className="text-gray-800">Discover other investors on Trade Verse and copy their traders.</p>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-14 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={e} className='' />
              <h3 className="text-xl font-medium mb-2 text-blue-800 mt-10">Free Daily News and Tradin...</h3>
                <p className="text-gray-800">Get the latest market updates in your Trade Verse Feed and copy trading signals from the selected providers.</p>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-14 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={f} className='' />
              <h3 className="text-xl font-medium mb-2 text-blue-800 mt-10">Personal Account Manager</h3>
                <p className="text-gray-800">Receive professional support from your account manager. They are available via phone, chat or email.</p>
            </div>
          </div>
        </div>
      </section>

      {/* About */}
      <section className='mt-28'>
        <div className="container flex lg:flex-row flex-col jusify-between items-center lg:space-x-10 space-x-0 space-y-10 lg:space-y-0 mx-auto px-4">
          <div className='lg:hidden block'>
            <img src={iq2} alt=''/>
          </div>
          <div>
            <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-blue-950'>About</span> <span className='text-green-950'>-Trade Verse</span></h1>
            <p className="text-lg mb-6 text-gray-700">Trade Verse is a moderately new retail trading platform compared to <br /> MetaQuotes' platform. It offers ultra-fast order execution capabilities and <br /> user-friendly  platform allowing you to enjoy Raw Pricing trading <br /> with No Dealing Desk trading from anywhere in the world.</p>
            <ul className='font-semibold flex flex-col space-y-10 mb-10'>
              <li>Professional Trading Tools</li>
              <li>1000+ Trading Assets</li>
              <li>Autocopy Other Traders</li>
              <li>Multiple Funding Methods</li>
            </ul>
          </div>
          <div className='lg:block hidden'>
            <img src={iq2} alt=''/>
          </div>
        </div>
      </section>

      {/* Product Overview */}
      <ProductOverview />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Company;
