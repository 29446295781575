import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'
import user from "../img/user.svg"

const Login = () => {
  let navigate = useNavigate()
  const submit = (e) => {
    e.preventDefault()
    const form = new FormData(document.querySelector("#form"))
    let body = {}
    for(let key of form.keys()) {
        body[key] = form.get(key)
    }
      fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json, text/plain, */*"
        },
        body: JSON.stringify(body)
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if (data.user == null) {
          let error = document.querySelector("#error")
          error.classList.remove("hidden")
          setTimeout(() => {
            error.classList.add("hidden")
          }, 5000)
        } else {
            sessionStorage.setItem("user", JSON.stringify(data.user))
            sessionStorage.setItem("depositUser", data.user.username)
            navigate("/dashboard")
        }
      })
      .catch(err => console.log(err))
  }
  return (
    <div className="bg-emerald-950">
          <Navbar />
          <div className="p-20">
            <p className="font-semibold text-white text-3xl">Login</p>
          </div>
      <div className="px-20 flex flex-row justify-between items-center">
        <img src={user} alt="" className="lg:block hidden order-2 w-80"/>
            <div className="py-4 lg:px-48 px-0 rounded-md w-auto container mb-20">
                <form action="" id="form">
                    <div className='bg-red-500 text-center p-2 rounded font-medium hidden mb-10 text-white' id="error">
                      Oops, no such user!
                    </div>
                    <div>
                        <p className="text-white mb-3">Username<span className="text-red-700">*</span></p>
                        <input type="text" id="username" name="username" className="p-3 lg:w-96 w-auto bg-transparent border border-solid border-white text-white rounded-md"/>
                    </div>
                    <div>
                        <p className="text-white my-4">Password<span className="text-red-700">*</span></p>
                        <input type="text" id="password" name="password" className="p-3 lg:w-96 w-auto bg-transparent border border-solid border-white text-white rounded-md"/>
                    </div>
                    <button onClick={submit} className="mt-10 p-3 rounded font-semibold hover:font-bold bg-green-600 text-white w-full">Login</button>
                    <div className="mt-4">
                        <p className="text-white">Don't have an account? <Link to="/register" className="text-green-600 hover:text-green-400">Register</Link></p>
                    </div>
                </form>
            </div>
      </div>
      <hr />
          <Footer />
    </div>
  )
}

export default Login