import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import ProductOverview from './ProductOverview'
import copyImg from "../img/copy.png"
import copy2 from "../img/copy2.png"
import copy3 from "../img/copy3.png"
import start from "../vid/start.mp4"

function Copy() {
  return (
      <div className="bg-gray-50 text-gray-800">
          <Navbar />
      {/* Hero */}
      <section className="py-16 bg-emerald-950 mb-20">
        <div className="container mx-auto px-4">
          <div className='flex lg:flex-row flex-col justify-between items-center lg:space-x-10 lg:space-y-0 space-x-0 space-y-10'>
                      <img src={copyImg} alt=""/> 
                      <div className='lg:block hidden'>
                          <p className='text-white font-medium lg:text-5xl text-xl'>Copy The Trades Of Other Traders, </p>
                          <p className='text-white font-medium lg:text-5xl text-xl'>- All In One App</p>
                          <br />
                          <p className='text-gray-300 mb-10'>At Trade Verse, we believe that everyone should have easy, fast and free access to the global financial markets. That’s why we’ve built the most powerful app for finance.</p>
                          <Link to="/register" className='rounded-md bg-emerald-500 text-white py-3 px-10 hover:bg-emerald-600 hover:font-medium'>Start Trading</Link>
                      </div>       
                      <div className='lg:hidden block text-center'>
                          <p className='text-white font-medium lg:text-5xl text-xl'>Copy The Trades Of Other Traders,</p>
                          <p className='text-white font-medium lg:text-5xl text-xl'>- All In One App</p>
                          <br />
                          <p className='text-gray-300 mb-10'>At Trade Verse, we believe that everyone should have easy, fast and free access to the global financial markets. That’s why we’ve built the most powerful app for finance.</p>
                          <Link to="/register" className='rounded-md bg-emerald-500 text-white py-3 px-10 hover:bg-emerald-600 hover:font-medium'>Start Trading</Link>
                      </div>       
          </div>
        </div>
          </section>

          {/* Our Community */}
      <section className='mt-28 mb-10'>
        <div className="container flex lg:flex-row flex-col jusify-between items-center lg:space-x-10 lg:space-y-0 space-x-0 space-y-10 mx-auto px-4">
          <div>
            <video src={start} muted autoPlay loop></video>
          </div>
          <div>
            <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-blue-950'>When Experts Trade, </span> <span className='text-green-950'>You Earn</span></h1>
                      <p className="text-lg mb-6 text-gray-700">Choose the trader you’d like to Autocopy, deposit the amount of funds you’d like to allocate on the platform and Autocopy starts when approved.</p>
                      <p className="text-lg mb-10 text-gray-700">You just started mirroring the Lead Trader’s positions automatically and in real time!</p>
            <Link to="/register" className='bg-green-500 rounded-md text-white font-medium hover:bg-green-600 hover:font-semibold py-4 px-4'>
              Start Trading
            </Link>
          </div>
        </div>

      </section>
          




      {/* Invest */}
          <section className='bg-black py-10'>
            <div className='container mx-auto'>
                <div className="flex lg:flex-row flex-col jusify-between items-center lg:space-x-10 space-y-0 space-x-0">
                    <div className='lg:order-2 order w-full lg:px-0 px-5'>
                        <img src={copy2} alt="" />
                    </div>
                    <div className='w-full lg:px-0 px-5'>
                        <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-white'>Copy Trades Of Other </span> <span className='text-green-500'>Traders</span></h1>
                        <p className='font-semibold text-green-500 text-xl'>Try Copy Trading — Discover True Social Trading</p>
                        <p className="text-lg mb-6 text-gray-300">Diversify your trading and try following the strategies of our <br /> Lead traders. Mirror their trading activity. <br /> They trade, you trade! Their potential becomes yours too!</p>
                    </div>
                </div>
                <div className="mt-10 lg:mt-0 flex lg:flex-row flex-col jusify-between items-center lg:space-x-10 space-x-0">
                    <div className='w-full lg:px-0 px-5 lg:mb-0 mb-10'>
                        <img src={copy3} alt="" />
                    </div>
                    <div className='w-full lg:px-0 px-5'>
                        <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-white'>Follow Lead Traders and copy their trading activity with </span> <span className='text-green-500'>Trade Verse Autocopy</span></h1>
                        <div>
                            <p className='font-semibold text-green-500 text-xl'>$235,979</p>  
                            <p className="text-lg mb-6 text-gray-300">Profits generated by our top 10 traders for their copiers during the last month In August 2023</p>  
                        </div>
                        <div>
                            <p className='font-semibold text-green-500 text-xl'>88.01%</p>  
                            <p className="text-lg mb-6 text-gray-300">Average win ratio of Trade Verse 10 most profitable traders In August 2023</p>  
                        </div>
                        <Link to="/register" className='bg-green-700 font-medium hover:font-semibold p-3 rounded-md text-white'>Try Copy Trading Now</Link>
                    </div>
                </div>
            </div>
      </section>

      {/* Product Overview */}
      <ProductOverview />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Copy;
