import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../img/logo.png"

const Navbar = () => {
  const show = () => {
    const sub = document.getElementById("sub")
    sub.classList.remove("hidden")
  }
  const close = () => {
    const sub = document.getElementById("sub")
    sub.classList.add("hidden")
  }
  const openNav = () => {
      const bars = document.querySelector("#bars")
      const close = document.querySelector("#close")
      const mobileNav = document.querySelector("#mobileNav")
      bars.classList.add("hidden")
      close.classList.remove("hidden")
      mobileNav.classList.remove("hidden")
    }
    const closeNav = () => {
      const bars = document.querySelector("#bars")
      const close = document.querySelector("#close")
      const mobileNav = document.querySelector("#mobileNav")
      bars.classList.remove("hidden")
      close.classList.add("hidden")
      mobileNav.classList.add("hidden")
    }
  return (
    <div className='bg-white shadow lg:px-20 px-8'>
      <div className='flex flex-row justify-between items-center font-medium py-5'>
        <div className='flex flex-row justify-between items-center'>
          <img src={logo} alt="" className='w-8 mr-2' /> 
          <p className='text-xl font-semibold'><span className='text-blue-950'>Trade</span><span className='text-green-950'> Verse</span></p>
        </div>
        
        <div className='lg:flex hidden flex-row justify-between items-center space-x-20'>
            <Link to="/" className='hover:text-green-700 text-sm'>Home</Link>
            <Link to="/company" className='hover:text-green-700 text-sm'>Company</Link>
            <Link to="/copy" className='hover:text-green-700 text-sm'>Copy Trading</Link>
          <Link to="/product" className='hover:text-green-700 text-sm'>Product</Link>
          <Link to="/contact" className='hover:text-green-700 text-sm'>Contact Us</Link>
          <Link to="/login" className='hover:text-green-700 text-sm'>Login</Link>
            <Link to="/register" className='bg-green-600 rounded-full flex flex-row justify-between items-center space-x-2 text-violet-950 py-2 px-4'>
              <i class="las la-user-plus"></i>
              <p className='hover:font-bold font-semibold'>Trade Now</p>
            </Link>
        </div>
        <div className='lg:hidden block text-customDarkBlue'>
          <i className='la la-bars la-2x hover:cursor-pointer hover:text-customYellow' id='bars' onClick={ openNav }></i>
          <i className='la la-times la-2x hidden hover:cursor-pointer hover:text-customYellow' id='close' onClick={ closeNav }></i>
        </div>
      </div>
      <div className='flex flex-col justify-between items-start space-y-5 pb-10 hidden' id='mobileNav'>
        <Link to="/" className='hover:text-customYellow'>Home</Link>
        <Link to="/company" className='hover:text-green-700 text-sm'>Company</Link>
            <Link to="/copy" className='hover:text-green-700 text-sm'>Copy Trading</Link>
          <Link to="/product" className='hover:text-green-700 text-sm'>Product</Link>
          <Link to="/contact" className='hover:text-green-700 text-sm'>Contact Us</Link>
          <Link to="/login" className='hover:text-green-700 text-sm'>Login</Link>
            <Link to="/register" className='bg-green-600 rounded-full flex flex-row justify-between items-center space-x-2 text-customBlack py-2 px-4'>
              <i class="las la-user-plus"></i>
              <p className='hover:font-bold'>Trade Now</p>
            </Link>
      </div>
    </div>
  )
}

export default Navbar