import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar'
import Footer from './Footer'
import ProductOverview from './ProductOverview'
import banner from "../img/banner.png"
import logo from "../img/logo.png"
import world from "../img/world.png"
import stock from "../img/stock.png"
import social from "../img/social.png"
import reliable from "../img/reliable.png"
import secured from "../img/secured.png"
import global from "../img/global.png"
import assets from "../img/assets.png"
import support from "../img/support.png"
import socialize from "../img/socialize.png"
import animation from "../vid/animation.mp4"
import fund from "../vid/fund.mp4"
import trade from "../vid/trade.mp4"
import register from "../vid/register.mp4"
import verify from "../vid/verify.mp4"
import boost from "../vid/boost.mp4"
import set from "../vid/set.mp4"
import pick from "../vid/pick.mp4"
import copy from "../vid/copy.mp4"

function HomePage() {
  return (
      <div className="bg-gray-50 text-gray-800">
          <Navbar />
      {/* Hero Section */}
      <section className="py-20 text-start">
              <div className="container flex flex-row jusify-between items-center mx-auto px-4">
          <div className=''>
            <img src={logo} className='mb-10' />
                      <h1 className="lg:text-4xl text-2xl font-bold mb-6"><span className='text-blue-950'>Copy, Trade & Earn With</span> <span className='text-green-950'>Trade Verse</span></h1>
                      <p className="text-lg mb-6 text-gray-700">Join the world's leading innovative trading app.</p>
                      <div className='flex lg:flex-row flex-col justify-start items-center lg:space-x-4 space-y-4 lg:space-y-0 space-x-0'>
                        <Link to="/login" className='bg-green-500 lg:rounded-full rounded-md text-center text-violet-950 py-2 px-4 w-full'>
                        <p className='hover:font-bold font-semibold'>Start Trading</p>
                        </Link>
                        <Link to="/login" className='bg-green-500 lg:rounded-full rounded-md text-violet-950 py-2 text-center w-full'>
                        <p className='hover:font-bold font-semibold'>Client Area</p>
                      </Link>
            </div>
                  </div>
                  <div>
                      <img src={banner} alt="" className='lg:block hidden'/>
                  </div>
          
        </div>
      </section>

      {/* Invest */}
      <section>
        <div className="container flex lg:flex-row flex-col jusify-between items-center lg:space-x-10 space-x-0 space-y-10 lg:space-y-0 mx-auto px-4">
          <div>
            <video src={animation} autoPlay muted loop />
          </div>
          <div>
            <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-blue-950'>Invest with confidence, </span> <span className='text-green-950'>follow and copy top financial leaders</span></h1>
            <p className="lg:text-lg text-sm mb-6 text-gray-700">With Trade Verse's cutting-edge CopyTrader™, you can effortlessly mirror the strategies of successful investors. Discover traders you trust and replicate their actions in real time.</p>
            <button to="/login" className='bg-green-500 rounded-md text-violet-950 py-4 px-4'>
              <p className='hover:font-bold font-semibold'>Start Copying</p>
            </button>
          </div>
        </div>

      </section>

      {/* Our Community */}
      <section className='mt-28'>
        <div className="container flex lg:flex-row flex-col jusify-between items-center lg:space-x-10 space-x-0 space-y-10 lg:space-y-0 mx-auto px-4">
          <div>
            <img src={world} className='' />
          </div>
          <div>
            <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-blue-950'>Join our big trading </span> <span className='text-green-950'>Trade Verse community</span></h1>
            <p className="text-lg mb-6 text-gray-700">Register and join our big community with hundreds of thousands of traders from all around the world.</p>
            <button to="/login" className='bg-green-500 rounded-md text-violet-950 py-4 px-4'>
              <p className='hover:font-bold font-semibold'>Join Our Trading Comunity</p>
            </button>
          </div>
        </div>

      </section>

      {/* Boost your Experience */}
      <section className='mt-28'>
        <div className="container flex lg:flex-row flex-col jusify-between items-center lg:space-x-10 space-x-0 space-y-10 lg:space-y-0 mx-auto px-4">
          <div className='lg:hidden block'>
            <video src={boost} autoPlay muted loop />
          </div>
          <div>
            <h1 className="lg:text-4xl text-xl font-bold mb-6 text-blue-950">Boost Your Trading Experience</h1>
            <p className="text-lg mb-6 text-gray-700">Trade Verse is the one of the top choices for expert traders. Our order matching engine located in the New York <br /> Equinix NY4 data centre processes over <br /> 500,000 trades per day with over two thirds of all <br /> trades coming from trading systems.</p>
            <ul className='font-bold flex flex-col space-y-10'>
              <li>Professional Trading Tools</li>
              <li>1000+ Trading Assets</li>
              <li>Autocopy Other Traders</li>
              <li>Multiple Funding Methods</li>
            </ul>
            <button to="/login" className='bg-green-500 rounded-md text-violet-950 py-4 px-4 mt-10'>
              <p className='hover:font-bold font-semibold'>Start Trading</p>
            </button>
          </div>
          <div className='lg:block hidden'>
            <video src={boost} autoPlay muted loop />
          </div>
        </div>
      </section>

      {/* Trading Steps */}
      <section className="py-16 bg-emerald-950 mt-10">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8 text-white">Start Trading In 4 Steps</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8" style={{ backgroundImage: `url(${logo})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
            <div
                className="bg-black rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition"
              >
              <video src={register} autoPlay muted loop className='mt-5'/>
              <h3 className="text-2xl font-semibold mb-2 text-green-500 mt-5">Register</h3>
                <p className="text-gray-400">Sign up for a free Trade Verse account and get access to a copytrade dashboard to try out the platform.</p>
            </div>
            <div
                className="bg-black rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition"
              >
              <video src={verify} autoPlay muted loop className='mt-5'/>
              <h3 className="text-2xl font-semibold mb-2 text-green-500 mt-5">Verify</h3>
                <p className="text-gray-400">Fill in the questionnaire about your trading experience and send us your proof of ID and residence.</p>
            </div>
            <div
                className="bg-black rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition"
              >
              <video src={fund} autoPlay muted loop className='mt-5' />
              <h3 className="text-2xl font-semibold mb-2 text-green-500 mt-5">Fund Account</h3>
                <p className="text-gray-400">Make a deposit via any funding method you prefer to start trading with real money on Trade Verse.</p>
            </div>
            <div
                className="bg-black rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition"
              >
              <video src={trade} autoPlay muted loop className='mt-5'/>
              <h3 className="text-2xl font-semibold mb-2 text-green-500 mt-5">Trade</h3>
                <p className="text-gray-400">Trades are opened and closed by top traders. Relax & Earn while they do the rest.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Stocks */}
      <section className='mt-28'>
        <div className="container flex lg:flex-row flex-col jusify-between items-center lg:space-x-10 space-x-0 space-y-10 lg:space-y-0 mx-auto px-4">
          <div>
            <img src={stock} className='' />
          </div>
          <div>
            <h1 className="lg:text-4xl text-xl font-bold mb-6 text-green-800">Stocks</h1>
            <p className="text-lg mb-6 text-gray-700">Go short or long on Stocks of the biggest companies and benefit from falling prices. With CFDs you can use leverage to increase your potential profit or losses.</p>
            <button to="/login" className='bg-green-500 rounded-md text-violet-950 py-4 px-4'>
              <p className='hover:font-bold font-semibold'>Start Investing</p>
            </button>
          </div>
        </div>

      </section>

      {/* Copy Trading */}
      <section className="py-16 bg-emerald-950 mt-10">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8 text-white">Try Copy Trading our Lead Traders</h2>
          <p className="text-center mb-8 text-white">Join and trade via a social network and profit from the knowledge of trading experts. <br /> Copy Lead Traders from around the world and reach your targets!</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8" style={{ backgroundImage: `url(${logo})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
            <div
                className="bg-black rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition"
              >
              <video src={pick} autoPlay muted loop className='mt-5'/>
              <h3 className="text-2xl font-semibold mb-2 text-green-500 mt-5">Pick a trader</h3>
                <p className="text-gray-400">Find a trader you like and make sure to check if the risk score suits you</p>
            </div>
            <div
                className="bg-black rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition"
              >
              <video src={set} autoPlay muted loop className='mt-5'/>
              <h3 className="text-2xl font-semibold mb-2 text-green-500 mt-5">Set the amount</h3>
                <p className="text-gray-400">Choose if you want to invest relative or a fixed amount per each trade</p>
            </div>
            <div
                className="bg-black rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition"
              >
              <video src={copy} autoPlay muted loop className='mt-5' />
              <h3 className="text-2xl font-semibold mb-2 text-green-500 mt-5">Click "Copy" to start</h3>
                <p className="text-gray-400">Begin mirroring the strategies of other traders automatically in real-time3</p>
            </div>
          </div>
        </div>
      </section>

      {/* Trusted Worldwide */}
      <section className="py-16 mt-10">
        <div className="container mx-auto px-4 flex flex-col items-center">
          <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-blue-950'>Trusted </span> <span className='text-green-950'>Worldwide</span></h1>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={social} className='' />
              <h3 className="text-2xl font-semibold mb-2 text-blue-800 mt-10">Social</h3>
                <p className="text-gray-800">More than 10 million users globally</p>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={reliable} className='' />
              <h3 className="text-2xl font-semibold mb-2 text-blue-800 mt-10">Reliable</h3>
                <p className="text-gray-800">A leader in the fintech space since 2019</p>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={secured} className='' />
              <h3 className="text-2xl font-semibold mb-2 text-blue-800 mt-10">Secured</h3>
                <p className="text-gray-800">Utilising best security practices for client money and assets safety</p>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={global} className='' />
              <h3 className="text-2xl font-semibold mb-2 text-blue-800 mt-10">Global</h3>
                <p className="text-gray-800">Providing services around the world!</p>
            </div>
          </div>
        </div>
      </section>

      {/* One Platform */}
      <section className="py-16 mt-10">
        <div className="container mx-auto px-4 flex flex-col items-center">
          <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-blue-950'>Everything </span> <span className='text-green-950'>In One Platform</span></h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={support} className='' />
              <h3 className="text-xl font-semibold mb-2 text-sky-950 mt-10">Professional Support</h3>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={socialize} className='' />
              <h3 className="text-xl font-semibold mb-2 text-sky-950 mt-10">Socialize With A Global Community</h3>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-lg p-6 text-center hover:shadow-xl transition hover:border-2 hover:border-green-500 hover:cursor-pointer"
              >
              <img src={assets} className='' />
              <h3 className="text-xl font-semibold mb-2 text-sky-950 mt-10">1500+ Tradable Assets</h3>
            </div>
          </div>
        </div>
      </section>

      {/* Product Overview */}
      <ProductOverview />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default HomePage;
