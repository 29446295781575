import React, { useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'

const Deposit = () => {
    const msg = useRef()
    const confirm = useRef()
  let navigate = useNavigate()
  useEffect(()=> {
    if(sessionStorage.getItem("uploaded") == "true") {
        window.stop()
        sessionStorage.setItem("uploaded", "false")
        msg.current.classList.remove("hidden")
        setTimeout(() => {
            msg.current.classList.add("hidden")
        }, 5000)
        window.location.href = "#msg"
    }
  })
    const copyAddress = (e) => {
        e.preventDefault()
        navigator.clipboard.writeText(e.target.parentElement.firstElementChild.value)
        alert("Address Copied!")
    }
    const confirmPay = (e) => {
        e.preventDefault()
        confirm.current.classList.toggle("hidden")
    }
  const submit = (e) => {
      e.preventDefault()
    const form = new FormData(document.querySelector("#form"))
    let body = {}
    for(let key of form.keys()) {
        body[key] = form.get(key)
        body.user = sessionStorage.getItem("user")
        body.username = JSON.parse(sessionStorage.getItem("user")).username
      }
      fetch("/api/deposit", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json, text/plain, */*"
        },
        body: JSON.stringify(body)
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
            fetch("/api/createUserDashboard", {
                method: "POST",
                headers: {
                "Content-type": "application/json",
                "Accept": "application/json, text/plain, */*"
                },
                body: JSON.stringify(body)
            })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                navigate("/dashboard")
            })
            .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200">
            <DashboardNav />
            <div className='bg-white shadow-md rounded-md my-10 w-full p-10 container mx-auto'>
                    <p className='text-emerald-800 font-semibold text-xl px-5 mb-5'>PAYMENT INFORMATION</p>
                    <hr />
                <div className="flex lg:flex-row flex-col lg:justify-start justify-between items-start lg:items-center lg:space-x-5 space-x-0 lg:space-y-0 space-y-5 mt-10">
                    <input type="text" id="btcaddress" name="btcaddress" className="p-3 lg:w-96 w-80 bg-transparent border border-solid border-gray-600 text-dark rounded-md" value="1PnrQjgEyPM6BBboPCXB3Sgk9GneswrNAx" disabled/>
                    <button className='bg-blue-600 text-white rounded-md p-3 lg:w-auto w-full' onClick={copyAddress}>Copy BTC</button>
                </div>
                <div className="flex lg:flex-row flex-col lg:justify-start justify-between items-start lg:items-center lg:space-x-5 space-x-0 lg:space-y-0 space-y-5 mt-10">
                    <input type="text" id="btcaddress" name="btcaddress" className="p-3 lg:w-96 w-80 bg-transparent border border-solid border-gray-600 text-dark rounded-md" value="0x439Ae5aaf0d0940DAf3819932F076793240cFad7" disabled/>
                    <button className='bg-blue-600 text-white rounded-md p-3 lg:w-auto w-full' onClick={copyAddress}>Copy ETH</button>
                </div>
                <div className="flex lg:flex-row flex-col lg:justify-start justify-between items-start lg:items-center lg:space-x-5 space-x-0 lg:space-y-0 space-y-5 mt-10">
                    <input type="text" id="btcaddress" name="btcaddress" className="p-3 lg:w-96 w-80 bg-transparent border border-solid border-gray-600 text-dark rounded-md" value="TDn1HkCKUUfJ8oi88PcebC3gHvF61XJdWn" disabled/>
                    <button className='bg-blue-600 text-white rounded-md p-3 lg:w-auto w-full' onClick={copyAddress}>Copy USDT TRC20</button>
                </div>
                <div className="flex lg:flex-row flex-col lg:justify-start justify-between items-start lg:items-center lg:space-x-5 space-x-0 lg:space-y-0 space-y-5 mt-10">
                    <input type="text" id="btcaddress" name="btcaddress" className="p-3 lg:w-96 w-80 bg-transparent border border-solid border-gray-600 text-dark rounded-md" value="LdxNTh91s6JWw8mFuAVS5MYvx5HfNYeg1j" disabled/>
                    <button className='bg-blue-600 text-white rounded-md p-3 lg:w-auto w-full' onClick={copyAddress}>Copy LTC</button>
                </div>
                <div className="flex lg:flex-row flex-col lg:justify-start justify-between items-start lg:items-center lg:space-x-5 space-x-0 lg:space-y-0 space-y-5 mt-10">
                    <input type="text" id="btcaddress" name="btcaddress" className="p-3 lg:w-96 w-80 bg-transparent border border-solid border-gray-600 text-dark rounded-md" value="ngGB33yH5aHXhcHwtrzJUDRG4NTer4h2fSsrEYw5E5X" disabled/>
                    <button className='bg-blue-600 text-white rounded-md p-3 lg:w-auto w-full' onClick={copyAddress}>Copy SOL</button>
                </div>
            </div>
            <div id="msg" className="p-10 flex flex-col justify-between items-center">
                <button className='bg-emerald-600 text-white p-3 rounded-md mb-5 hover:font-medium' onClick={confirmPay}>I HAVE MADE PAYMENT</button>
                {/* <div className="flex flex-row justify-end items-center mb-10">
                    <Link to="/deposit-history" className="p-4 rounded-md bg-customYellow text-black font-medium hover:font-semibold">Deposit History</Link>
                </div> */}
                <div ref={msg} className="p-4 rounded bg-green-800 text-green-400 border border-solid border-green-400 my-10 hidden">
                    <p>Thanks for depositing, we'll get back to you soon!</p>
                </div>
                
                <div className="bg-white py-4 px-10 rounded-md lg:w-7/12 w-80 container mb-20 shadow-md hidden" id='confirm' ref={confirm}>
                    <p className='text-emerald-600 font-semibold text-sm mb-5'>SUBMIT NOTIFICATION FOR DEPOSIT</p>
                    <hr />
                    <form action="" className="mt-4" id="form">
                        <div>
                            <p className="font-semibold text-emerald-600 mb-3">Select Gateway</p>
                            <select id="gateway" name="gateway" className="p-3 w-full text-dark rounded border border-solid border-gray-600">
                                <option value="">Select One</option>
                                <option value="Bitcoin">Bitcoin</option>|
                                <option value="Solana">Solana</option>|
                                <option value="Litecoin">Litecoin</option>|
                                <option value="Ethereum (ERC20)">Ethereum (ERC20)</option>
                                <option value="USDT (TRC20)">USDT (TRC20)</option>
                            </select>
                        </div>
                        <div>
                            <p className="font-semibold text-emerald-600 my-4">Amount</p>
                            <div className="flex flex-row justify-between items-center">
                                <input type="number" id="depositAmount" name="depositAmount" className="p-3 w-full border border-solid border-gray-600 text-dark rounded-tl-md rounded-bl-md"/>
                                <p className="p-3 bg-emerald-600 text-white rounded-tr-md rounded-br-md">USD</p>
                            </div>
                        </div>
                        <button onClick={submit} className="p-3 rounded font-medium hover:font-semibold bg-emerald-600 text-white w-full my-10">Notify for Deposit</button>
                    </form>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }
}

export default Deposit