import React from 'react'
import { Link } from 'react-router-dom';
import cta from "../img/cta.png"

const ProductOverview = () => {
  return (
      <div>
          <section className="py-16 bg-gray-100">
        <div className="container mx-auto lg:px-4 px-0">
          <div className="lg:grid flex flex-col grid-cols-2 md:grid-cols-1 gap-8">
            {['Forex CFDs'].map((product, index) => (
              <div
                key={index}
                className="bg-black flex lg:flex-row flex-col justify-center items-center rounded-xl shadow-lg py-10 lg:px-0 px-4 text-center hover:shadow-xl transition"
              >
                <img src={cta} alt="" />
                <div className='text-start'>
                  <h1 className="lg:text-4xl text-xl font-bold mb-6"><span className='text-blue-700'>Invest, Copy, Trade </span> <span className='text-green-700'>— Earn all in one App</span></h1>
                  <p className='text-white'>At Trade Verse, we believe that everyone <br /> should have easy, fast and free access to the global financial markets.</p>
                  <br />
                  <Link to="/login" className='bg-green-500 rounded-md text-white py-4 px-4 mt-5 hover:font-bold font-semibold'>
                    Create An Account - It's Free
                  </Link>
                </div>
                
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProductOverview