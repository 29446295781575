import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets"
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'

const Dashboard = () => {
  const [total, setTotal] = useState(0)
  const interest = useRef()
    useEffect(() => {
       let data = {
        username: JSON.parse(sessionStorage.getItem("user")).username
       }
        fetch("/api/getUser", {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              "Accept": "application/json, text/plain, */*"
            },
            body: JSON.stringify(data)
          })
          .then((res) => {
            return res.json()
          })
          .then((data) => {
            setTotal(data.user[0].deposit)
          })
          .catch(err => console.log(err))
  }, [total])
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200 overflow-x-hidden">
              <DashboardNav />
              <div className="p-20">
                <p className="font-semibold text-green-800 text-3xl">Dashboard</p>
              </div>
              <div className="flex flex-col justify-between items-center">
                <div className="flex lg:flex-row flex-col justify-between items-center space-x-0 lg:space-x-2 container lg:space-y-0 space-y-10">
                    <div className='flex flex-col justify-between items-center'>
                        <div className='flex lg:flex-row flex-col justify-between items-center space-x-0 lg:space-x-5 lg:space-y-0 space-y-10 mb-10'>
                            <div className="bg-emerald-800 p-4 rounded-md lg:w-96 w-80">
                                <div className="flex flex-row justify-between items-center space-x-20">
                                    <div>
                                        <p className="text-white font-semibold">Total Balance</p>
                                        <p className="text-white font-bold text-xl">${total}</p>
                                        <button className="mt-10 p-3 rounded font-semibold hover:font-bold bg-emerald-600 text-white lg:w-full w-full text-xs">Switch to Demo Trade Account</button>
                                    </div>
                                    <div className="p-4 rounded-full w-10 h-10 items-center bg-emerald-600 text-white flex flex-col justify-center items-center lg:flex hidden">
                                        <i class="las la-wallet"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-emerald-800 p-4 lg:w-auto w-96 rounded-md">
                                <div className='flex flex-col justify-center items-center'>
                                    <div className="flex flex-row justify-between items-center space-x-3 mb-5">
                                        <div className="p-4 w-10 h-10 flex flex-col items-center justify-center rounded-full bg-yellow-600 text-white">
                                            <i class="lab la-bitcoin"></i>
                                        </div>
                                        <p className='text-white'>Bitcoin</p>
                                        <p className='text-white lg:block hidden'>BTC</p>
                                        <div className='flex flex-row justify-betwee items-center space-x-4'>
                                            <Link to="/deposit" className='bg-emerald-600 rounded-md flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
                                                <p className='hover:font-bold font-medium'>Deposit</p>
                                                </Link>
                                                <Link to="/withdraw" className='bg-stone-600 rounded-md flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
                                                <p className='hover:font-bold font-medium'>Withdraw</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-between items-center space-x-3">
                                        <div className="p-4 w-10 h-10 flex flex-col items-center justify-center rounded-full bg-violet-600 text-white">
                                            <i class="lab la-ethereum"></i>
                                        </div>
                                        <p className='text-white'>Ethereum</p>
                                        <p className='text-white lg:block hidden'>ETH</p>
                                        <div className='flex flex-row justify-betwee items-center space-x-4'>
                                            <Link to="/deposit" className='bg-emerald-600 rounded-md flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
                                                <p className='hover:font-bold font-medium'>Deposit</p>
                                                </Link>
                                                <Link to="/withdraw" className='bg-stone-600 rounded-md flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
                                                <p className='hover:font-bold font-medium'>Withdraw</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="lg:hidden block">
                                <AdvancedRealTimeChart theme="light" width={350}></AdvancedRealTimeChart>
                            </div>
                            <div className="lg:block hidden">
                                <AdvancedRealTimeChart theme="light"></AdvancedRealTimeChart>

                            </div>
                        </div>
                    </div>
                    <div className='w-80'>
                        <div className='bg-white rounded-md shadow-md'>
                            <p className='p-5 font-bold text-green-600'>TRADING SESSION</p>
                            <hr />
                            <form action="" className="mt-4 lg:px-5 py-5 px-10" id="form">
                                <div className='bg-red-500 text-center p-2 rounded font-medium hidden mb-10 text-white' id="error">
                                Oops, no such user!
                                </div>
                                <div>
                                    <p className="mb-3 font-medium text-green-600">Type</p>
                                    <input type="text" id="type" name="amount" className="p-2 lg:w-full w-full bg-transparent border border-solid border-gray-500 rounded-md"/>
                                </div>
                                <div>
                                    <p className="my-4 font-medium text-green-600">Amount</p>
                                    <input type="text" id="amount" name="amount" className="p-2 lg:w-full w-full bg-transparent border border-solid border-gray-500 rounded-md"/>
                                </div>
                                <div>
                                    <p className="my-4 font-medium text-green-600">Lot Size</p>
                                    <input type="text" id="lotsize" name="lotsize" className="p-2 lg:w-full w-full bg-transparent border border-solid border-gray-500 rounded-md"/>
                                </div>
                                <div>
                                    <p className="my-4 font-medium text-green-600">Take Profit</p>
                                    <input type="text" id="takeprofit" name="takeprofit" className="p-2 lg:w-full w-full bg-transparent border border-solid border-gray-500 rounded-md"/>
                                </div>
                                <div>
                                    <p className="my-4 font-medium text-green-600">Stop Loss</p>
                                    <input type="text" id="stoploss" name="stoploss" className="p-2 lg:w-full w-full bg-transparent border border-solid border-gray-500 rounded-md"/>
                                </div>
                                <div>
                                    <p className="my-4 font-medium text-green-600">Time in Force</p>
                                    <input type="text" id="time" name="time" className="p-2 lg:w-full w-full bg-transparent border border-solid border-gray-500 rounded-md"/>
                                </div>
                                <button className="mt-10 p-3 rounded font-semibold hover:font-bold bg-green-600 text-white lg:w-full w-60">Place Order</button>
                            </form>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="flex flex-col justify-between items-center my-20 lg:w-full w-80 lg:overflow-x-hidden overflow-x-scroll">
                    <table>
                      <thead className="bg-emerald-800 text-white">
                        <tr>
                          <th className="rounded-tl-md p-2">Type</th>
                          <th>Pair</th>
                          <th>Action</th>
                          <th>Entry</th>
                          <th>SL</th>
                          <th>TP</th>
                          <th className="rounded-tr-md p-2">Profit</th>
                        </tr>
                      </thead>
                      <tbody className="bg-black text-white shadow shadow-customYellow">
                        <tr>
                            <td className="px-10"></td>
                            <td className="px-32"></td>
                            <td className="px-20"></td>
                            <td className="px-10 py-4">No Transaction Found</td>
                            <td className="px-32"></td>
                            <td className="px-20"></td>
                            <td className="px-20"></td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }

}

export default Dashboard