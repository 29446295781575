import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'

const Assets = () => {
  const [assets, setAssets] = useState({})
    useEffect(() => {
    let data = {
        username: JSON.parse(sessionStorage.getItem("user")).username
    }
    fetch("/api/getUser", {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              "Accept": "application/json, text/plain, */*"
            },
            body: JSON.stringify(data)
          })
          .then((res) => {
            return res.json()
          })
        .then((data) => {
            setAssets({
                btc: data.user[0].btc,
                eth: data.user[0].eth,
                xrp: data.user[0].xrp,
                dash: data.user[0].dash,
                xmr: data.user[0].xmr,
                ltc: data.user[0].ltc,
                rise: data.user[0].rise,
                bts: data.user[0].bts,
                deposit: data.user[0].deposit
            })
          })
          .catch(err => console.log(err))  
  }, [assets])
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200 overflow-x-hidden">
              <DashboardNav />
              <div className="px-20 py-5">
                <p className="font-semibold text-green-800 text-3xl">Assets</p>
              </div>
            <div className="flex flex-col justify-between items-start px-5">
                <div className="bg-emerald-800 p-4 rounded-md">
                    <div className="flex flex-row justify-between items-center space-x-20">
                        <div>
                            <p className="text-white font-semibold">Total Balance</p>
                            <p className="text-white font-bold text-xl">${assets.deposit}</p>
                            <button className="mt-10 p-3 rounded font-semibold hover:font-bold bg-emerald-600 text-white lg:w-full w-auto text-xs">Switch to Demo Trade Account</button>
                        </div>
                        <div className="p-4 rounded-full w-10 h-10 items-center bg-emerald-600 text-white flex flex-col justify-center items-center lg:flex hidden">
                            <i class="las la-wallet"></i>
                        </div>
                    </div>
                </div>
                <div className='bg-white shadow-md rounded-md my-10 w-full p-10 lg:overflow-x-hidden overflow-x-scroll'>
                    <p className='text-emerald-800 font-semibold text-xl px-5 mb-5'>FIAT</p>
                    <hr />
                    <div className='flex flex-row justify-between items-center text-gray-700 space-x-20 mt-10'>
                        <div>
                            <p className='font-bold'>Asset</p>
                            <br />
                            <p className='font-medium'>USD</p>
                        </div>
                        <div>
                            <p className='font-bold'>Name</p>
                            <br />
                            <p className='font-medium'>United States Dollars</p>
                        </div>
                        <div>
                            <p className='font-bold'>Value</p>
                            <br />
                            <p className='font-medium'>${0.50 * assets.deposit}</p>
                        </div>
                        <div>
                            <p className='font-bold'>Action</p>
                            <br />
                            <Link to="/deposit" className='bg-emerald-800 text-white p-3 rounded'>Deposit</Link>
                        </div>
                    </div>
                </div>
                <div className='mb-10 w-full'>
                    <div className='flex lg:flex-row flex-col justify-between items-center lg:space-x-2 space-x-0 space-y-5 lg:space-y-0'>
                        <div className='flex flex-row justify-between items-center bg-white rounded-md p-5 space-x-5 w-full'>
                            <div className='flex flex-col justify-between items-start space-y-3'>
                                <div>
                                    <i class="lab la-bitcoin text-yellow-600"></i><p className='inline'>Bitcoin BTC</p>
                                </div>
                                <div>
                                    <span>0.00102932</span> <span className='text-blue-400'>$105.00</span>
                                </div>
                            </div>
                            <div className='flex flex-col justify-between items-start'>
                                <div>
                                    <button className='bg-emerald-600 text-white rounded-md p-2'>24h</button>
                                </div>
                                <div>
                                    <p className='text-red-600 text-sm'>+0.00%</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-center bg-white rounded-md p-5 space-x-5 w-full'>
                            <div className='flex flex-col justify-between items-start space-y-3'>
                                <div>
                                    <i class="lab la-ethereum text-violet-600"></i><p className='inline'>Ethereum ETH</p>
                                </div>
                                <div>
                                    <span>0.00</span> <span className='text-blue-400'>$105.00</span>
                                </div>
                            </div>
                            <div className='flex flex-col justify-between items-start'>
                                <div>
                                    <button className='bg-emerald-600 text-white rounded-md p-2'>24h</button>
                                </div>
                                <div>
                                    <p className='text-red-600 text-sm'>+0.00%</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-center bg-white rounded-md p-5 space-x-5 w-full'>
                            <div className='flex flex-col justify-between items-start space-y-3'>
                                <div>
                                    <i class="las la-share-alt-square text-blue-600"></i><p className='inline'>Ripple XRP</p>
                                </div>
                                <div>
                                    <span>0.00</span> <span className='text-blue-400'>$105.00</span>
                                </div>
                            </div>
                            <div className='flex flex-col justify-between items-start'>
                                <div>
                                    <button className='bg-emerald-600 text-white rounded-md p-2'>24</button>
                                </div>
                                <div>
                                    <p className='text-red-600 text-sm'>+0.00%</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-center bg-white rounded-md p-5 space-x-5 w-full'>
                            <div className='flex flex-col justify-between items-start space-y-3'>
                                <div>
                                    <i class="lab la-dashcube text-pink-600"></i><p className='inline'>Dash DASH</p>
                                </div>
                                <div>
                                    <span>0.00</span> <span className='text-blue-400'>$105.00</span>
                                </div>
                            </div>
                            <div className='flex flex-col justify-between items-start'>
                                <div>
                                    <button className='bg-emerald-600 text-white rounded-md p-2'>24</button>
                                </div>
                                <div>
                                    <p className='text-red-600 text-sm'>+0.00%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex lg:flex-row flex-col justify-between items-center lg:space-y-0 lg:space-x-2 space-x-0 space-y-5 mt-10'>
                        <div className='flex flex-row justify-between items-center bg-white rounded-md p-5 space-x-5 w-full'>
                            <div className='flex flex-col justify-between items-start space-y-3'>
                                <div>
                                    <i class="lab la-monero text-green-600"></i><p className='inline'>Monero XMR</p>
                                </div>
                                <div>
                                    <span>0.00</span> <span className='text-blue-400'>$105.00</span>
                                </div>
                            </div>
                            <div className='flex flex-col justify-between items-start'>
                                <div>
                                    <button className='bg-emerald-600 text-white rounded-md p-2'>24h</button>
                                </div>
                                <div>
                                    <p className='text-red-600 text-sm'>+0.00%</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-center bg-white rounded-md p-5 space-x-5 w-full'>
                            <div className='flex flex-col justify-between items-start space-y-3'>
                                <div>
                                    <p className='inline'>Solana SOL</p>
                                </div>
                                <div>
                                    <span>0.00</span> <span className='text-blue-400'>$105.00</span>
                                </div>
                            </div>
                            <div className='flex flex-col justify-between items-start'>
                                <div>
                                    <button className='bg-emerald-600 text-white rounded-md p-2'>24h</button>
                                </div>
                                <div>
                                    <p className='text-red-600 text-sm'>+0.00%</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-center bg-white rounded-md p-5 space-x-5 w-full'>
                            <div className='flex flex-col justify-between items-start space-y-3'>
                                <div>
                                    <p className='inline'>Rise RISE</p>
                                </div>
                                <div>
                                    <span>0.00</span> <span className='text-blue-400'>$105.00</span>
                                </div>
                            </div>
                            <div className='flex flex-col justify-between items-start'>
                                <div>
                                    <button className='bg-emerald-600 text-white rounded-md p-2'>24</button>
                                </div>
                                <div>
                                    <p className='text-red-600 text-sm'>+0.00%</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-center bg-white rounded-md p-5 space-x-5 w-full'>
                            <div className='flex flex-col justify-between items-start space-y-3'>
                                <div>
                                    <p className='inline'>Bts BTS</p>
                                </div>
                                <div>
                                    <span>0.00</span> <span className='text-blue-400'>$105.00</span>
                                </div>
                            </div>
                            <div className='flex flex-col justify-between items-start'>
                                <div>
                                    <button className='bg-emerald-600 text-white rounded-md p-2'>24</button>
                                </div>
                                <div>
                                    <p className='text-red-600 text-sm'>+0.00%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className='bg-white shadow-md rounded-md my-10 w-full p-10 lg:overflow-x-hidden overflow-x-scroll'>
                    <p className='text-emerald-800 font-semibold text-xl px-5 mb-5'>ASSETS</p>
                    <hr />
                    <div className='flex flex-row justify-between items-center text-gray-700 space-x-20 mt-10'>
                        <div>
                            <p className='font-bold'>Asset</p>
                            <br />
                            <p className='font-medium'><i class="lab la-bitcoin text-yellow-600 font-bold"></i>BTC</p>
                        </div>
                        <div>
                            <p className='font-bold'>Name</p>
                            <br />
                            <p className='font-medium'>Bitcoin</p>
                        </div>
                        <div>
                            <p className='font-bold'>Value</p>
                            <br />
                            <p className='font-medium'>${(assets.btc == "undefined") ? 0 : `${assets.btc}`}</p>
                        </div>
                        <div>
                            <p className='font-bold'>Action</p>
                            <br />
                            <Link to="/deposit" className='bg-emerald-800 text-white p-3 rounded'>Deposit</Link>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between items-center text-gray-700 space-x-20 mt-10'>
                        <div>
                            <p className='font-medium'><i class="lab la-ethereum text-violet-600 font-bold"></i>ETH</p>
                        </div>
                        <div>
                            <p className='font-medium'>Ethereum</p>
                        </div>
                        <div>
                            <p className='font-medium'>${(assets.eth == "undefined") ? 0 : `${assets.eth}`}</p>
                        </div>
                        <div>
                            <Link to="/deposit" className='bg-emerald-800 text-white p-3 rounded'>Deposit</Link>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between items-center text-gray-700 space-x-20 mt-10'>
                        <div>
                            <p className='font-medium'><i class="las la-share-alt-square text-blue-600 font-bold"></i>XRP</p>
                        </div>
                        <div>
                            <p className='font-medium'>Ripple</p>
                        </div>
                        <div>
                            <p className='font-medium'>${(assets.xrp == "undefined") ? 0 : `${assets.xrp}`}</p>
                        </div>
                        <div>
                            <Link to="/deposit" className='bg-emerald-800 text-white p-3 rounded'>Deposit</Link>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between items-center text-gray-700 space-x-20 mt-10'>
                        <div>
                            <p className='font-medium'><i class="lab la-dashcube text-pink-600 font-bold"></i>DASH</p>
                        </div>
                        <div>
                            <p className='font-medium'>Dash</p>
                        </div>
                        <div>
                            <p className='font-medium'>${(assets.dash == "undefined") ? 0 : `${assets.dash}`}</p>
                        </div>
                        <div>
                            <Link to="/deposit" className='bg-emerald-800 text-white p-3 rounded'>Deposit</Link>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between items-center text-gray-700 space-x-20 mt-10'>
                        <div>
                            <p className='font-medium'><i class="lab la-monero text-green-600 font-bold"></i>XMR</p>
                        </div>
                        <div>
                            <p className='font-medium'>Monero</p>
                        </div>
                        <div>
                            <p className='font-medium'>${(assets.xmr == "undefined") ? 0 : `${assets.xmr}`}</p>
                        </div>
                        <div>
                            <Link to="/deposit" className='bg-emerald-800 text-white p-3 rounded'>Deposit</Link>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between items-center text-gray-700 space-x-20 mt-10'>
                        <div>
                            <p className='font-medium'>LTC</p>
                        </div>
                        <div>
                            <p className='font-medium'>Litecoin</p>
                        </div>
                        <div>
                            <p className='font-medium'>${(assets.ltc == "undefined") ? 0 : `${assets.ltc}`}</p>
                        </div>
                        <div>
                            <Link to="/deposit" className='bg-emerald-800 text-white p-3 rounded'>Deposit</Link>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between items-center text-gray-700 space-x-20 mt-10'>
                        <div>
                            <p className='font-medium'>RISE</p>
                        </div>
                        <div>
                            <p className='font-medium'>Rise</p>
                        </div>
                        <div>
                            <p className='font-medium'>${(assets.rise == "undefined") ? 0 : `${assets.rise}`}</p>
                        </div>
                        <div>
                            <Link to="/deposit" className='bg-emerald-800 text-white p-3 rounded'>Deposit</Link>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between items-center text-gray-700 space-x-20 mt-10'>
                        <div>
                            <p className='font-medium'>BTS</p>
                        </div>
                        <div>
                            <p className='font-medium'>Bts</p>
                        </div>
                        <div>
                            <p className='font-medium'>${(assets.bts == "undefined") ? 0 : `${assets.bts}`}</p>
                        </div>
                        <div>
                            <Link to="/deposit" className='bg-emerald-800 text-white p-3 rounded'>Deposit</Link>
                        </div>
                    </div>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }

}

export default Assets