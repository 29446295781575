import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../img/logo.png"

const DashboardNav = () => {
  const openNav = () => {
      const bars = document.querySelector("#bars")
      const close = document.querySelector("#close")
      const mobileNav = document.querySelector("#mobileNav")
      bars.classList.add("hidden")
      close.classList.remove("hidden")
      mobileNav.classList.remove("hidden")
    }
    const closeNav = () => {
      const bars = document.querySelector("#bars")
      const close = document.querySelector("#close")
      const mobileNav = document.querySelector("#mobileNav")
      bars.classList.remove("hidden")
      close.classList.add("hidden")
      mobileNav.classList.add("hidden")
    }
  return (
    <div className='bg-white shadow lg:px-2 px-8'>
      <div className='flex flex-row justify-around items-center font-medium py-5'>
        <div className='flex flex-row justify-between items-center'>
          <img src={logo} alt="" className='w-8 mr-2' /> 
          <p className='text-xl font-semibold'><span className='text-blue-950'>Trade</span><span className='text-green-950'> Verse</span></p>
        </div>
        <div className='lg:flex hidden flex-row justify-between items-center space-x-10'>
            <Link to="/dashboard" className='hover:text-green-700 text-sm'>Dashboard</Link>
            <Link to="/assets" className='hover:text-green-700 text-sm'>Assets</Link>
            <Link to="/deposit" className='hover:text-green-700 text-sm'>Deposit</Link>
            <Link to="/deposit-history" className='hover:text-green-700 text-sm'>Deposit History</Link>
          <Link to="/withdraw" className='hover:text-green-700 text-sm'>Withdrawal</Link>
          <Link to="/withdraw-history" className='hover:text-green-700 text-sm'>Withdrawal History</Link>
          <Link to="/profile" className='hover:text-green-700 text-sm'>Profile</Link>
            <Link to="/register" className='bg-emerald-800 rounded-md flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
              <i class="las la-user-plus"></i>
              <p className='hover:font-bold'>Logout</p>
            </Link>
        </div>
        <div className='lg:hidden block text-customDarkBlue'>
          <i className='la la-bars la-2x hover:cursor-pointer hover:text-customYellow' id='bars' onClick={ openNav }></i>
          <i className='la la-times la-2x hidden hover:cursor-pointer hover:text-customYellow' id='close' onClick={ closeNav }></i>
        </div>
      </div>
      <div className='flex flex-col justify-between items-start space-y-5 pb-10 hidden' id='mobileNav'>
            <Link to="/dashboard" className='hover:text-green-700 text-sm'>Dashboard</Link>
            <Link to="/assets" className='hover:text-green-700 text-sm'>Assets</Link>
            <Link to="/deposit" className='hover:text-green-700 text-sm'>Deposit</Link>
            <Link to="/deposit-history" className='hover:text-green-700 text-sm'>Deposit History</Link>
          <Link to="/withdraw" className='hover:text-green-700 text-sm'>Withdrawal</Link>
          <Link to="/withdraw-history" className='hover:text-green-700 text-sm'>Withdrawal History</Link>
          <Link to="/profile" className='hover:text-green-700 text-sm'>Profile</Link>
            <Link to="/register" className='bg-emerald-800 rounded-md flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
              <i class="las la-user-plus"></i>
              <p className='hover:font-bold'>Logout</p>
            </Link>
      </div>
    </div>
  )
}

export default DashboardNav