import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'

const Withdraw = () => {
   const msg = useRef()
   const submit = (e) => {
    e.preventDefault()
    msg.current.classList.remove("hidden")
    setTimeout(() => {
        msg.current.classList.add("hidden")
    }, 5000)
   }
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200">
              <DashboardNav />
              <div className="p-20 flex flex-col justify-between items-center">
                <div ref={msg} className="p-4 rounded bg-green-800 text-green-400 border border-solid border-green-400 my-10 hidden">
                    <p>Thanks for withdrawing, we'll get back to you soon!</p>
                </div>
                <div className="bg-white py-4 px-10 rounded-md lg:w-7/12 w-80 container mb-20 border border-solid border-slate-400">
                    <p className='text-emerald-800 font-semibold text-xl px-5 mb-5'>WITHDRAWAL TRANSACTION</p>
                    <hr />
                    <form action="" className="mt-4">
                        <div>
                            <p className="font-semibold text-emerald-600 mb-3">Method</p>
                            <select id="country" name="country" className="p-3 w-full text-dark rounded border border-solid border-gray-600">
                                <option value="">Select Gateway</option>
                                <option value="cashapp">Cash app</option>
                                <option value="zelle">Zelle</option>
                                <option value="paypal">Paypal</option>
                                <option value="venom">Venom</option>
                                <option value="creditcard">Credit card</option>
                                <option value="giftcard">Gift card</option>
                            </select>
                        </div>
                        <div>
                            <p className="font-semibold text-emerald-600 my-4">Amount</p>
                            <div className="flex flex-row justify-between items-center">
                                <input type="number" id="amount" name="amount" className="p-3 w-full text-dark rounded border border-solid border-gray-600"/>
                                <p className="p-3 bg-emerald-600 text-white rounded-tr-md rounded-br-md">USD</p>
                            </div>
                        </div>
                        <button onClick={submit} className="p-3 rounded font-medium hover:font-semibold bg-emerald-600 text-white w-full my-10">Submit</button>
                    </form>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }

}

export default Withdraw